<template>
  <div class="app-slide-box">
     <head-block :activeIdx='activeManue'></head-block>
     <div class="container"> 
        <div class="back"> 
            <a-icon type="left"  @click='openBackConfirm'/>
            <span class="ml8" @click='openBackConfirm'>返回消息列表</span>
        </div> 
        <div class="commonWhiteBg newsDetail"> 
            <h3 class="bigTittle"> 
              {{rowData.tittle}}
            </h3>
            <div class="time"> 
              <span>{{rowData.time}}</span>
              <span>{{rowData.from}}</span>
            </div>

            <div v-show="rowData.flag == 1" class="newsDetail1">
              <div class="desc">
                <!-- {{ rowData.desc}} -->
                根据《关于组织申报区块链创新应用试点的通知》（中网办秘字〔2021〕1482号），经过中央网信办、中央宣传部、国务院办公厅[电子政务办公室]、最高人民法院、最高人民检察院、教育部、工业和信息化部、民政部、司法部、人力资源社会保障部、国家卫生健康委、中国人民银行、国家税务总局、中国银保监会、中国证监会、国家能源局、国家外汇管理局等17个中央委办部门和单位严格评审，<strong>中国知链获批“国家区块链创新应用试点”。</strong>
              </div>
              <div class="img">
                  <el-image :src="rowData.img" :preview-src-list="[rowData.img]"></el-image>
                  <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news02.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news02.png']" alt=""></el-image>
                  <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news03.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news03.png']" alt=""></el-image>
              </div>
            </div>
            
            <div v-show="rowData.flag == 2" class="newsDetail2">
              <div class="desc">
                3月29日下午，湖北省版权保护中心与可信大数据有限公司举行合作协议签字仪式，共同推进中国知链——国家区块链创新应用试点项目落地湖北并发展壮大。中心主任郑凌辉、可信公司董事长兼总经理胡江南出席签字仪式。
              </div>
              <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news04.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news04.png']" alt=""></el-image>
              <div class="desc">
                中国知链项目，由武汉大学和国家教育部教育信息管理中心技术团队开发，可信大数据有限公司推进实施。该项目是基于区块链技术的学术论文知识产权保护及认证体系建设的数字作品版权重大项目，拥有“中国知链”“基于区块链数字作品版权保护”“论文认证平台”“中国教育信息化论文首发平台”等知识产权。2021年12月底，经中央网信办、中宣部、工信部等17个中央委办部门和单位严格评审，该项目被列为“国家区块链创新应用试点”项目。据悉，此次公布的国家区块链创新应用试点项目名单中，武汉大学是“区块链+版权”特色领域全国唯一入选的高校。湖北省委宣传部（湖北省版权局）高度重视，部领导专门进行了批示和部署。
              </div>
              <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news05.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news05.png']" alt=""></el-image>
              <div class="desc">
                根据协议，双方将建立合作伙伴关系，实现优势互补，共同推进湖北数字作品版权产业创新发展。省版权保护中心将指导和支持可信公司融合区块链、人工智能（AI）、大数据等技术，应用“区块链+版权”模式，搭建网络化、智能化、一体化的湖北数字作品版权综合服务平台，并提供湖北省著作权登记系统的有关接口协议，确保二者互联互通；同时，支持其在学术领域提供版权登记代理、数字作品版权存证以及数字作品版权运营等服务，以及相关的技术咨询、信息资讯等服务，促进湖北版权服务及产业数字化转型升级。
              </div>
              <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news06.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news06.png']" alt=""></el-image>
              <div class="desc">
                签约前，中心主任郑凌辉对中心有关情况及湖北省著作权登记系统进行了简要介绍，表示将大力支持、热忱服务中国知链项目推进实施。可信大数据公司董事长兼总经理胡江南介绍了项目架构、项目申报及发展目标情况。双方还就著作权登记系统对接等有关工作进行了沟通交流。省版权保护中心及可信大数据公司相关负责人与工作人员、湖北省著作权登记系统维护单位技术人员等参加了签约仪式。
              </div>
            </div>

            <div v-show="rowData.flag == 3" class="newsDetail3">
              <div class="desc">
                3月31日下午，2022年武汉科技成果转化首场对接活动数字经济专场在武钢体育馆举行。本次会议成果提供方包括华中科技大学、武汉大学、武汉科技大学等一批在汉高校及科研院所，武汉钢铁有限公司、中冶集团武汉勘察研究院有限公司、可信大数据有限公司、黑芝麻智能科技有限公司等一批在汉企业为成果承接方。24个科技成果转化项目，签约总合作金额达3.1亿元以上。
              </div>
              <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news07.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news07.png']" alt=""></el-image>
              <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news08.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news08.png']" alt=""></el-image>
              <div class="desc" style="font-weight: bold;">武汉将引领全域发力数字经济</div>
              <div class="desc">
                数字经济是全球竞争的前沿领域。自“十四五”规划和2035年远景目标纲要发布以来，“建设数字中国”已成为中国社会发展确定性的方向。湖北省在“十四五”规划中提出，加快建设数字湖北，实施数字经济跃升工程。催生新产业新业态新模式。
                《湖北省数字经济发展（2021年）》称，从全省区域发展来看，数字经济呈现“强省会”“双支柱”等特点，“强省会”现象突出。据了解，武汉将充分发挥自身产业潜力，吸引中部地区数据、资本和人才集聚，成为带动全省乃至中部地区数字经济发展的牵引极。
                抢抓机遇，乘势发展。武汉市数字经济“十四五”发展规划即将出台，2020年市委市政府就提出“突破性发展数字经济”，到2025年，数字经济核心产业增加值将占全市GDP比重达到11%。
                插上数字经济的翅膀，传统产业的制造业变得更聪明，教育、医疗等优质资源通过云端传输，“指尖”可得。数字经济带动的将是全产业变革。
                <br>
              </div>
            </div>

            <div v-show="rowData.flag == 4" class="newsDetail3">
              <div class="desc">
                为深入贯彻习近平总书记思想，推动知识产权评价体系建设，落实国家知识产权局《知识产权强国建设纲要》和《关于加强知识产权鉴定工作的指导意见》，近日，在武汉大学和湖北省版权局的支持下“中文出版物知识产权评价中心”成立。
              </div>
              <el-image src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news10.png"
                    :preview-src-list="['https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news10.png']" alt=""></el-image>
              <div class="desc">中文出版物知识产权评价中心（简称著作权评价中心）是湖北省编制办批准下设立的事业法人机构，中心的宗旨和业务范围是中文出版物评价及标准研究制定，中文出版物知识产权及版权公益服务，相关培训、交流、会议等社会公益服务，促进知识产权建设和产业发展。该中心是国内唯一从事中文出版物知识产权评价的事业法人机构，承担中央网信办、中央宣传部、国家版权局国家级区块链创新应用试点项目“中国知链”。</div>
              <div class="desc">
                为了响应落实中共中央办公厅、国务院办公厅《关于深化职称制度改革的意见》和国家人力资源社会保障部《关于进一步做好职称评审工作的通知》，国家职称评审政策重大改革，职称评定实行“代表作制度”，推行代表性成果评价，中文出版物知识产权评价中心成立“中文出版物代表作评价服务平台”，建立“中文出版物代表作数据库”，开展第三方专业中文出版物事业公益性评价评审服务。
              </div>
              <div class="desc">
                在知识产权强国大背景下，中文出版物知识产权评价中心积极推进中文出版物评价标准制定和认证体系建设，必将产生深远影响。
              </div>
            </div>
        </div>

     </div>
   
    <foot-block></foot-block>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeadBlock from '@/components/common/HeadBlock'
import FootBlock from '@/components/common/FootBlock'
export default {
  name: 'inputView',
  components: {
    FootBlock,
    HeadBlock,
  },
  props: {
    // 配件需求确认行数据
    rowData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
     
    }
  },
      computed: {
    ...mapGetters(['activeManue'])
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    
    },
   // 返回列表
     openBackConfirm() {
      this.$emit('dialog-close')
    },
  }
}
</script>

<style lang="scss" scoped>
.app-slide-box{
  padding-top: 84px;
}
.back{
  margin:16px 0;
  display: flex;
  align-items: center;
  &>*{
    cursor: pointer;
  }
}
.newsDetail{
  text-align: center;
  padding: 28px 4%;
   .bigTittle{
     font-size: 24px;
   }
   .desc{
     font-size: 16px;
     text-align: left;
     text-indent: 2em;
     line-height: 36px;
   }
   .time{
      color: rgba(0, 18, 22, 0.65);
      margin:8px 0 24px;
      span{margin: 0 20px;}
   }
   .img{
     max-width: 90%;
     margin: 24px auto;
    //  max-height: 250px;
     .el-image{width: 50%;display: block;margin: 20px auto;}
   }
   .newsDetail2{
     .el-image{width: 80%;display: block;margin: 20px auto;}
   }
   .newsDetail3{
     .el-image{width: 80%;display: block;margin: 20px auto;}
   }
}
</style>
