<template>
  <div class="news">
    <div class="container">
      <h3 class="bigTittle">
        新闻资讯
      </h3>
      <ul class="newsList">
        <li
          v-for="(item,index) in newsList2"
          :key="index"
          @click="onEditOrder(item)"
        >
          <div class="contentCol">
            <h4 class="tittle">{{item.tittle}}</h4>
            <div class="desc">{{item.desc}}</div>
            <div class="time">{{item.time}}</div>
          </div>
          <div class="imgCol">
            <img :src="item.img">
          </div>
        </li>
      </ul>
      <!-- <div class="seeMore">
        <span>查看全部</span>
        <a-icon type="right" />
      </div> -->
    </div>
    <div class="container">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pagination.total"
        :current-page.sync="pagination.page"
        :page-size.sync="pagination.pageSize"
        @size-change="sizeChange"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

      <!--新增入库单抽屉-->
    <transition name="slide">
      <input-view
        v-if="isInputDrawerShow"
        :rowData="currentRepairOrderRow"
        class="slide-dialog"
        @dialog-close="onInputDrawerClose"
      />
    </transition>

  </div>
</template>

<script>
import InputView from './NewsDetail/InputView'
export default {
  name: 'newsList',
 components: {
    InputView
  },
  data () {
    return {
        // 录入/补录 抽屉显示
      isInputDrawerShow: false,
      // 外修单ID
      currentRepairOrderRow: {},
      newsList: [
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news01.png',
          tittle: '国家级区块链创新应用试点',
          desc: '根据《关于组织申报区块链创新应用试点的通知》（中网办秘字〔2021〕1482号），经过中央网信办、中央宣传部、国务院办公厅[电子政务办公室]、最高人民法院、最高人民检察院、教育部、工业和信息化部、民政部、司法部、人力资源社会保障部、国家卫生健康委、中国人民银行、国家税务总局、中国银保监会、中国证监会、国家能源局、国家外汇管理局等17个中央委办部门和单位严格评审，中国知链获批“国家区块链创新应用试点”。',
          time: '2021-12-22',
          flag: 1,
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news04.png',
          tittle: '湖北省版权保护中心与可信大数据签约共推中国知链项目',
          desc: '3月29日下午，湖北省版权保护中心与可信大数据有限公司举行合作协议签字仪式，共同推进中国知链——国家区块链创新应用试点项目落地湖北并发展壮大。中心主任郑凌辉、可信公司董事长兼总经理胡江南出席签字仪式。',
          time: '2022-03-30',
          from: '来源：湖北省版权保护中心 作者：刘迟 文 / 图 ',
          flag: 2,
        },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news07.png',
          tittle: '可信大数据有限公司参加武汉市首场数字经济大会',
          desc: '3月31日下午，2022年武汉科技成果转化首场对接活动数字经济专场在武钢体育馆举行。本次会议成果提供方包括华中科技大学、武汉大学、武汉科技大学等一批在汉高校及科研院所，武汉钢铁有限公司、中冶集团武汉勘察研究院有限公司、可信大数据有限公司、黑芝麻智能科技有限公司等一批在汉企业为成果承接方。24个科技成果转化项目，签约总合作金额达3.1亿元以上。',
          time: '2022-04-01',
          flag: 3,
        },
        // {
        //   img: 'https://baikewh.oss-cn-hangzhou.aliyuncs.com/static/ipchain/newImg.png',
        //   tittle: '知链平台简介2',
        //   desc: '段落示意222：蚂蚁金服设计平台 design.alipay.com，用最小的工作量，无缝接入蚂蚁金服生态，提供跨越设计与开发的体验解决方案。蚂蚁金服设计平台 design.alipay.com，用最小的工作量，无缝接入蚂蚁金服生态，提供跨越设计与开发的体验解决方案。',
        //   time: '2017-10-01 18:00'
        // },
        {
          img: 'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/news10.png',
          tittle: '中文出版物知识产权评价中心成立',
          desc: '为深入贯彻习近平总书记思想，推动知识产权评价体系建设，落实国家知识产权局《知识产权强国建设纲要》和《关于加强知识产权鉴定工作的指导意见》，近日，在武汉大学和湖北省版权局的支持下“中文出版物知识产权评价中心”成立。',
          time: '2023-02-23',
          flag: 4,
        },
      ],
      newsList2: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total:0
      },
    }
  },

  mounted () {
    // this.init()
    this.pagination.total = this.newsList.length*1
    this.initPageLists()
  },
  methods: {
    init () {
      this.$store.commit('setActiveManue', 6)

    },
    // 录入/补录 抽屉打开
    onEditOrder(data) {
      console.log("onEditOrder...")
      this.currentRepairOrderRow = data
      this.isInputDrawerShow = true
    },
     // 录入/补录 抽屉关闭
    onInputDrawerClose() {
      this.isInputDrawerShow = false
    },

    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.newsList2 = this.newsList.slice((pageNum-1)*10,pageNum*10)
    },
    initPageLists(){
        this.newsList2 = this.newsList.slice(0,10)
    }
  }
}
</script>

<style lang='scss' scoped>
.news {
  min-height: 650px;
  padding-bottom: 50px;
  .container:first-child{
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.06);
    margin: 16px auto;
    padding: 28px 0 0;
    color: rgba(0, 18, 22, 0.85);
    .bigTittle {
      padding: 4px 4%;
      font-size: 24px;
    }
  }
}
.newsList {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 4%;
    cursor: pointer;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
    .imgCol {
      width: 10%;
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .contentCol {
      width: 70%;
      .tittle {
        font-size: 20px;
      }
      .desc {
        color: rgba(0, 18, 22, 0.65);
        margin: 16px 0 14px;
      }
      .time{
          color: rgba(0, 18, 22, 0.65); 
      }
    }
  }
  &>li:last-child{
      border-bottom: none;
  }
}
.seeMore {
  background: #fafafa;
  padding: 17px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 18, 22, 0.65);
  cursor: pointer;
  .anticon {
    margin-left: 4px;
    font-size: 12px;
  }
}
.el-pagination{
    text-align: right;
    margin-top:16px;
}
@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .newsList {
    li {
        flex-wrap: wrap;
      .imgCol {
        order: 1;
        width: 100%;
        max-height: 250px;
        overflow: hidden;
        margin-bottom: 16px;
      }
      .contentCol {
        order: 2;
        width: 100%;
      }
    }
  }
}
</style>
